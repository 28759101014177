import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import PrimaryHeader from '../../components/atoms/typography/PrimaryHeader';
import SEO from '../../components/atoms/utility/seo';
import DownloadAppCTA from '../../components/atoms/callouts/DownloadAppCTA';
import MapApp from '../../components/organisms/MapApp';
import SubHeader from '../../components/atoms/typography/SubHeader';

const mapData = {
  mapBounds: JSON.stringify({
    xmin: -84.67769867012228,
    ymin: 42.634417613333625,
    xmax: -84.3682757960331,
    ymax: 42.83227543401418
  }),
  lng: -84.5268,
  lat: 42.7195,
  zoom: 11.35
};

export default function LansingCommunityPage({
                                               data: { grandRapidsCommunityHeroImg, grandRapidsCommunityMobileHeroImg }
                                             }) {
  return (
    <BaseLayout
      heroContent={GrandRapidsCommunityPageHeroContent}
      heroImg={grandRapidsCommunityHeroImg}
      mobileHeroImg={grandRapidsCommunityMobileHeroImg}
    >
      <SEO
        title='Live Activities in Lansing at Your Finger Tips'
        keywords={[
          'tacos',
          'taquiero',
          'food',
          'influencer',
          'community',
          'exploration',
          'experience',
          'inspiration',
          'live music',
          'mobile app',
          'flockx'
        ]}
        description={`Looking for things to do in Lansing, Michigan? Try Flockx, the new mobile app that helps you find live events and activities in your area. From concerts and festivals to sports games and theater performances, Flockx has everything you need to plan your next outing. Download the app now and discover all that Michigan has to offer!`}
      />
    </BaseLayout>
  );
}

function GrandRapidsCommunityPageHeroContent() {
  return (
    <div className='-mt-[50%] sm:mt-0'>
      <div className='text-center sm:text-left sm:mt-6 lg:mt-0 grid grid-cols-1 sm:grid-cols-12'>
        <div className='sm:col-span-6 mb-10 md:mb-0'>
          <PrimaryHeader
            headerText='Live Activities at Your Finger Tips'
            className='!text-4xl lg:!text-6xl xl:!text-[72px] xl:!leading-[90px] mb-4 lg:mb-4 text-white capitalize max-w-[390px] sm:max-w-sm md:max-w-md lg:max-w-xl mx-auto lg:mr-auto sm:ml-0 lg:ml-0 lg:mt-10'
          />
          <SubHeader
            headerText='See what other students are up to in Lansing'
            className='mb-4 md:mb-8 text-white mx-auto font-light max-w-xs sm:max-w-sm lg:max-w-md sm:mx-0'
          />
          <DownloadAppCTA containerStyles='justify-center sm:justify-start' />
          <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
            Available in the US
          </p>
        </div>
        <div className='sm:col-span-5 sm:col-start-7 xl:col-start-8 min-h-[250px] md:min-h-[0px]'>
          <MapApp mapData={mapData} />
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
    query LansingCommunityPageQuery {
        grandRapidsCommunityHeroImg: file(name: { eq: "grand-rapids-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        grandRapidsCommunityMobileHeroImg: file(
            name: { eq: "beer-community-mobile-hero" }
        ) {
            childImageSharp {
                gatsbyImageData
            }
        }
        phoneDemoImg: file(name: { eq: "fetch-app-demo-2" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, width: 445)
            }
        }
    }
`;
